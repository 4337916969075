import {route as baseRoute} from 'ziggy-js';
import {Ziggy} from '@/ziggy';

const ziggyConfig = {
    ...Ziggy,
    location: typeof window !== 'undefined'
        ? window.location
        : null
};

let route = (name, params, absolute, config = ziggyConfig) => baseRoute(name, params, absolute, config);

export default route;
